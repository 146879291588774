.whyAppInformationContainer {
  padding: var(--cds-spacing-extralarge-rem-100) 0;
  margin-bottom: -28px;
  color: var(--cds-color-monochrome-black);
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;

  @media (--s) {
    padding-right: var(--cds-spacing-medium-rem-300);
    padding-left: var(--cds-spacing-medium-rem-300);
  }

  @media (--m) {
    padding: var(--cds-spacing-extralarge-rem-100) 0;
  }
}

.whyAppInformationSectionHeading {
  margin-bottom: var(--cds-spacing-large-rem-200);
  color: var(--cds-color-primary-deep-purple);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.whyAppInformationSectionList {
  display: grid;
  padding-bottom: var(--cds-spacing-small-rem-200);
  grid-template-columns: repeat(1, 1fr);

  @media (--s) {
    gap: var(--cds-spacing-large-rem-300);
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--m) {
    gap: var(--cds-spacing-large-rem-300);
    grid-template-columns: repeat(4, 1fr);
  }
}

.whyAppInformationListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--cds-spacing-large-rem-200);
}

.whyAppInformationIconCard {
  width: var(--cds-spacing-giant-rem-200);
  height: var(--cds-spacing-giant-rem-200);
  padding: var(--cds-spacing-large-rem-100);
  margin: 0 auto var(--cds-spacing-medium-rem-300) auto;
  background-color: var(--cds-color-monochrome-white);
  border-radius: 27px;
  box-shadow: 0 6px 24px rgb(90 98 126 / 10%);
}

.whyAppInformationIconCard svg {
  color: var(--cds-color-primary-purple);
}

.whyAppInformationIconCardText {
  max-width: 300px;
}

.whyAppInformationDisclaimer {
  padding-bottom: var(--cds-spacing-large-rem-300);
  font-size: 18px;
  font-weight: 700;
}

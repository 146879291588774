/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.qrWrapper {
  padding: 50px 0;
}

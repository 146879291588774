.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (--s) {
    flex-direction: row;
    gap: var(--cds-spacing-medium-rem-200);
  }

  @media (--m) {
    gap: 77px;
  }
}

.title {
  padding-bottom: var(--cds-spacing-medium-rem-300);
  color: var(--cds-color-primary-deep-purple);
  font-weight: var(--ds-font-weight-bold);
  line-height: var(--ds-space-6-x);
}

.qrImage {
  max-width: 232px;
  margin-bottom: var(--cds-spacing-medium-rem-300);
  @media (--m) {
    max-width: 320px;
  }
}

.listWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (--s) {
    flex-direction: column;
  }
}

.featureItem {
  display: flex;
  padding-bottom: var(--cds-spacing-medium-rem-300);
}

.featureIcon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  padding: var(--cds-spacing-small-rem-100);
  margin-right: var(--cds-spacing-medium-rem-100);
  background-color: var(--cds-color-secondary-neutral);
  border-radius: 50%;
}

.icon {
  color: var(--cds-color-primary-deep-purple);
}

.featureText {
  height: 32px;
  flex: auto;
}
